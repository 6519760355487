.data-grid-row{
    align-items: center;
}

.dx-datagrid-headers .cls{  
    font-family: Poppins, Helvetica, "sans-serif";
    font-weight: bold;
}  

.dx-datagrid-headers .cls:focus{  
    color: #3699FF;
}  

.table-area {
    padding-top: 30px;
}

.head-top {
    z-index: 1;
    top: -30px;
    left: 4px;
    margin-bottom: 20px;
}

.mt-25 {
    margin-top: 6.25rem !important;
}

.dx-layout-manager .dx-label-h-align .dx-field-item-label {
    white-space: nowrap;
    vertical-align: middle;
    word-break: break-word;
    min-width: 40%;
}


.dx-layout-manager .dx-label-h-align .dx-field-item-label .dx-field-item-label-content {
    width: 100% !important;
}
.dx-layout-manager .dx-label-h-align .dx-field-item-label .dx-field-item-label-text {
    white-space: break-spaces !important;
}