.ical-providers{
    border: 1px solid #ccc;
    border-radius: 6px;
    height: 50px;
    width: 100%;
    position: relative;
    filter: grayscale(1);
    padding: 12px 10px;
    transition: all ease 0.5s;

    &:hover{
        filter: grayscale(0) !important;
        transition: all ease 0.5s;
    }

    img{
            width: 100%;
                height: 100%;
                object-fit: contain;
                object-position: center;
    }
}

.service-card {
    border: 1px solid #e1e1e1 !important;
    min-height: 170px !important;
    height: 100% !important;
    text-align: center;
    padding: 40px 10px;
    cursor: pointer;
}

.service-card.selected {
    border: 1px solid #444 !important;
}

.service-checklist-btn {
    z-index: 99999;
    position: absolute;
    bottom: 10px;
    width: 100%;
    height: 50px;
    text-align: center;
}

.review-line {
    color: #FE5D5D;
    text-align: center;
    margin-top: 4px;
    font-size: 14px;
    cursor: pointer;
}

.review-detail-row {
    list-style: none;
    .score {
    display: flex;
    height: 30px;
    align-items: center;
    margin: 6px 0;
    }
}

.svg-icon.trustbadge svg [fill]:not(.permanent):not(g) {
    fill: #fff !important
}

.offer-action-buttons {
    position: absolute;
    left: 0;
    bottom: 10px;
    padding: 0 12px;
    width: 100%;
}

@media screen and (max-width: 600px) {
    .offer-action-buttons {
        position: relative !important;
        left: unset !important;
        bottom: unset !important;
        padding: 0 !important;
        width: 100%;
    }
}

.card-warning-bar {
        position: fixed;
        z-index: 9999;
            top: 0;
            left: 0;
            height: 60px;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            background: orange;
}

.checklist-title {
    cursor: pointer !important;
}

.checklist-title.active {
    background-color: #f1faff !important;
    color: #009ef7 !important;
    padding-top: 6px;
    padding-bottom: 6px;
    padding-left: 20px;
    padding-right: 20px;
}

.checklist-sections {
    display: none;
}

.checklist-sections.active {
    display: block !important;
}

.zi-top {
    z-index: 999999 !important;
}

.nav.nav-pills.nav-pills-custom .nav-link .bullet-custom,
.nav.nav-pills.nav-pills-custom .show>.nav-link .bullet-custom {
    display: none;
}

.nav.nav-pills.nav-pills-custom .nav-link.active .bullet-custom,
.nav.nav-pills.nav-pills-custom .show>.nav-link.active .bullet-custom {
    display: block !important;
}

.nav.nav-pills.nav-pills-custom .nav-link.active,
.nav.nav-pills.nav-pills-custom .show>.nav-link.active {
    background-color: transparent;
    position: relative;
}

.cursor-pointer {
    cursor: pointer !important;
}

.pt-30 {
    padding-top: 7.5rem !important;
}

.object-contain {
    object-fit: contain !important;
}

.dropfilter {
    position: absolute;
    top: 80px !important;
}

.dropcl {
    position: absolute !important;
    left: -100px !important;
        top: 40px !important;
}

.dropcl2 {
    position: absolute !important;
    left: -50px !important;
    top: 40px !important;
}

.staff-group-scroll {
    max-width: 50vw !important;
    overflow: scroll !important;
    .staff-group-item {
        min-width: 70px !important;
    }
}

@media screen and (max-width: 600px) {
    .staff-group-scroll {
        max-width: calc(100vw - 64px) !important;
        .staff-group-item {
            min-width: 150px !important;
        }
        
    }

        .chat-drawer {
            width: 100vw !important;
        }
}

td.staff-available div.dx-texteditor-input-container {
    display: flex !important;
}

.chat-drawer {
    width: 35vw !important;
    z-index: 999999 !important;
}

.btn-turnify {
    background-color: #ff5d5d;
    color: #fff;
}

.auth-sticky-headbar {
    position: static;
        top: 0;
        left: 0;
        width: 100vw;
        height: 70px;
        background: #fff;
        box-shadow: 0 5px 30px -17px rgba(95, 102, 97, .67);

    .head-content {
        margin: auto;
            position: relative;
            width: 100%;
            display: flex;
                height: 70px;
                align-items: center;

        .head-content-items{
            margin-left: auto;
                margin-right: auto;
                left: 0;
                right: 0;
                display: flex;
                justify-content: center;
                align-items: center;

            .divider {
                width: 40vw;
            }
        }
    }
}