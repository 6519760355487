.dx-datagrid .cls {
  font-family: Poppins, Helvetica, 'sans-serif';
  padding: 5px !important;
}

.dx-datagrid .bl {
  border-left: 1px solid #e0e0e0 !important;
}

.dx-datagrid-headers .cls {
  padding: 0px !important;
}

.cls {
  vertical-align: middle !important;
}

.dx-datagrid-headers .cls:focus {
  color: #3699ff;
}

.dx-datagrid-header-panel {
  border: 0px !important;
}

.nav-tabs {
  border-bottom: 1px solid #eff2f5 !important;
}

.dx-datagrid-headers {
  background-color: #f5f8fab4 !important;
  padding: 10px 0px 10px 10px;
  border-radius: 10px !important;
  border: 0px !important;
  text-transform: uppercase !important;
  color: #8d9ab0 !important;
  font-size: 14px !important;
  letter-spacing: 0px !important;
  line-height: 15px !important;
}
.dx-datagrid-content {
  background-color: transparent !important;
}
.dx-editor-cell .dx-texteditor,
.dx-editor-cell .dx-texteditor .dx-texteditor-input {
  background: transparent !important;
  height: 25px;
}
.dx-datagrid-headers .dx-datagrid-table .dx-row > td {
  border-bottom: 0 solid white !important;
}

.dx-datagrid .dx-column-indicators .dx-header-filter.dx-header-filter,
.dx-datagrid .dx-column-indicators .dx-header-filter.dx-sort,
.dx-datagrid .dx-column-indicators .dx-sort.dx-header-filter,
.dx-datagrid .dx-column-indicators .dx-sort.dx-sort {
  display: inline-block;
  color: rgb(255, 93, 93);
  margin-left: 2px;
}

.dx-datagrid-rowsview {
  margin: 5px 0px 5px 5px;
  border-top: 0px !important;
}

.absolute-right {
  position: absolute;
  left: 5px;
  bottom: 0px;
}

.dx-datagrid-total-footer {
  border: 0px !important;
}
.close{
  border:0px;
  background: transparent;
}

.PhoneInputInput {
  display: block !important;
  width: 100% !important;
  padding: 0.75rem 1rem !important;
  font-size: 1.1rem !important;
  font-weight: 500 !important;
  line-height: 1.5 !important;
  color: #181c32 !important;
  background-color: #ffffff !important;
  background-clip: padding-box !important;
  border: 1px solid #e4e6ef !important;
  -webkit-appearance: none !important;
  appearance: none !important;
  border-radius: 0.475rem !important;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075) !important;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out !important;
}

.searchbar{
  width: 400px !important;
  max-height: 200px !important;
  overflow-y: auto !important;
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  position: absolute !important;
  box-shadow: none !important;
  transition: all 0.7s;
  top: -35px !important;
  left: -30px !important;
}

.autocomplete-dropdown-container{
  margin-top: 0px;
  max-height: 350px;
  overflow-y: auto;
  padding-left: 12px;
  padding-right: 12px;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.1);
  z-index: 1;
}

.MuiPaper-root {
  overflow-y: unset !important;
}

td div.dx-texteditor-input-container {
  display: none !important;
}

.dx-filter-range-content {
  padding: 10px 0px 2px 0px !important;
  color: #a0a0a0 !important;
}

.dx-filter-range-content::after {
  content: "Filter..." !important;
}