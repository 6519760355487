.active-step{
    color: red;
}

.active-step:hover{
    color: #ffaaaa;
    cursor: pointer;
}

.step{
    color: black;
}

.step:hover{
    color: #a0a0a0;
    cursor: pointer;
}