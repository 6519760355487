.success-check {
    border-radius: 50px;
    width: 16px;
    height: 16px;
    background-color: rgb(80, 205, 137);
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 8px;
}

.pro-yes-btn {
    font-family: 'Manrope',sans-serif;
    font-size: 1.2rem;
    line-height: 1.2!important;
    font-weight: 400;
    font-style: normal;
    text-transform: none;
    letter-spacing: 0em;
    border-radius: 1.3em;
    padding: 0.6em 1.2em;
    background: #fff;
    border-color: transparent;
    color: rgb(10, 210, 16)!important;
    box-shadow: 0 0em 0em rgb(0 0 0 / 20%);
    margin-top: 16px;
    margin-bottom: 16px;
}

.pro-no-btn {
    font-family: 'Manrope',sans-serif;
    font-size: 1.2rem;
    line-height: 1.2!important;
    font-weight: 400;
    font-style: normal;
    text-transform: none;
    letter-spacing: 0em;
    border-radius: 1.3em;
    padding: 0.6em 1.2em;
    background: #fff;
    border-color: transparent;
    color: #ff0000!important;
    box-shadow: 0 0em 0em rgb(0 0 0 / 20%);
    margin-top: 16px;
    margin-bottom: 16px;
}

.pro-learn-more {
    font-size: 18px;
    text-decoration: underline;
}

.pro-learn-more a {
    color : #fff !important;
}

.pro-teaser-bottom-bar2 {
    padding-top: 16px;
    height: 100px;
    background-color: #ff5d5d;
    text-align: center;
}